import { t } from "@lingui/macro";
import { useAppDispatch, useAppSelector } from "app/hooks";
import InputField from "components/InputField";
import { validate } from "email-validator";
import { selectLogin, setLogin } from "features/login/loginSlice";
import { flowAsync } from "features/lostPassword/lostPasswordSlice";
import FormWrapper from "features/page/FormWrapper";
import React, { useEffect, useState } from "react";

type LostPasswordFormProps = {
  onSend: () => void;
  prefilledMail?: string;
};
const LostPasswordForm: React.FC<LostPasswordFormProps> = (props) => {
  const [mailIsValid, setMailIsValid] = useState(true);
  const dispatch = useAppDispatch();
  const login = useAppSelector(selectLogin);
  const sendResetForm = (e?: React.MouseEvent) => {
    e?.preventDefault();
    if (!mailIsValid) {
      return;
    }
    props.onSend?.();
    dispatch(flowAsync(login));
  };
  // MR-2410: auto send reset form when an email was provided
  useEffect(() => {
    console.log("props.prefilledMail", props.prefilledMail);
    console.log("login", login);
    if (props.prefilledMail === login) {
      sendResetForm();
    }
  }, [props.prefilledMail, login]);

  useEffect(() => {
    if (props.prefilledMail) {
      console.log("setLogin", props.prefilledMail);
      dispatch(setLogin(props.prefilledMail));
    }
  }, [props.prefilledMail]);

  return (
    <FormWrapper
      className="lost-password"
      onBtnClick={sendResetForm}
      okText={"Abschicken"}
    >
      <InputField
        prefilledMail={props.prefilledMail}
        required={true}
        name="email"
        type="email"
        label={t`email`}
        selector={selectLogin}
        dispatchTo={setLogin}
        validator={validate}
        onValid={() => {
          setMailIsValid(true);
        }}
        onInvalid={() => setMailIsValid(false)}
      />
    </FormWrapper>
  );
};

export default LostPasswordForm;
